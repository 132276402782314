import { Creator } from "~/components/sections/creator";
import { FAQ } from "~/components/sections/faq";
import { Features } from "~/components/sections/features";
import { Footer } from "~/components/sections/footer";
import { Hero } from "~/components/sections/hero";
import { Waitlist } from "~/components/sections/waitlist";
// import { ThemeDropdown } from "~/components/solid-ui/theme-dropdown";

export default function Home() {
  return (
    <>
      {/* <div>
        <ThemeDropdown />
      </div> */}
      <div>
        <Hero />
        <Features />
        <Waitlist />
        <Creator />
        <FAQ />
      </div>
      <Footer />
    </>
  );
}
