import { Index } from "solid-js";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "~/components/solid-ui/accordion";

const faq = [
  [
    "What is this, really?",
    "Setting up a community is tough. Most tools brand your emails or charge you for them. I wanted something for me to start building without worrying about scalability. I want to tell people interested about what I'm building, not worry if perhaps I'll have to pay more for it.",
  ],
  [
    "Is it secure?",
    "All our data is stored in SOC2 Type II compliant databases. So is our E-Mail Sending Service.",
  ],
  [
    "Can I really have unlimited users?",
    <span>
      Yes. No <i>ifs</i>, no <i>buts</i>. Grow as much as you can, I dare you!
    </span>,
  ],
  [
    "You said metrics. What metrics?",
    "You can't grow a communinity in the dark. You'll have absolute information about delivery, open, and click rates.",
  ],
  [
    "Can I take my data?",
    "I don't believe in vendor lock-in. The first feature I added is the ability to export all your data. What's yours is yours and I'm not doing anything with it.",
  ],
  [
    "What about Open-Source plans?",
    "I love open-source and I want to support it. You'll be able to build waitlist for your non-commercial open-source for free as soon as I can afford it.",
  ],
];

export function FAQ() {
  return (
    <section class="max-w-prose mx-auto pt-24">
      <h2 class="font-heading text-6xl text-amber-300 mb-16 text-center">
        F<span class="text-neutral-200 font-thin px-1">.</span>A
        <span class="text-neutral-200 font-thin">.</span>Q
      </h2>

      <Accordion multiple={true} collapsible>
        <Index each={faq}>
          {(item, idx) => (
            <AccordionItem value={`item-${idx}`}>
              <AccordionTrigger>
                <span class="text-lg ">{item()[0]}</span>
              </AccordionTrigger>
              <AccordionContent>
                <span class="text-lg text-neutral-300">{item()[1]}</span>
              </AccordionContent>
            </AccordionItem>
          )}
        </Index>
      </Accordion>
    </section>
  );
}
