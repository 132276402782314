import { Listup } from "~/components/icons/listup";

export function Hero() {
  return (
    <header class=" relative px-10 overflow-hidden font-extrabold flex flex-col items-center justify-center">
      <div class="flex items-center gap-10">
        <Listup />
        <h1 class="text-9xl font-heading font-normal animate-fade-in-down text-center">
          List
          <span class="text-amber-400 font-extrabold text-[1em] animate-pulse">
            Up
          </span>
        </h1>
      </div>

      <strong class="max-w-2xl text-3xl block text-orange-100 mt-12 mx-auto">
        Start growing your community in 5min.
      </strong>
    </header>
  );
}
