import { Spoiler } from "~/components/custom/spoiler";
import Trophy from "lucide-solid/icons/trophy";
import Laptop from "lucide-solid/icons/laptop";

export function Creator() {
  return (
    <section class="max-w-prose mx-auto grid grid-cols-2 py-10 gap-7">
      <div>
        <img
          class="w-28 rounded-full shadow-md shadow-sky-400/30"
          src="/atila-square.png"
          alt="Atila"
        />
        <p class="pt-7">
          I enjoy building things and I'd like a system to keep waitlists and{" "}
          <Spoiler>
            contact with people interested in what I'm building. Once I was
            done,
          </Spoiler>
          I figured y'all might want that too.
        </p>
      </div>
      <div class="grid place-items-center">
        <div>
          <h2 class="text-3xl mb-5 font-heading">
            <span class="text-amber-400 font-extrabold text-[1em]">’sup! </span>
            I’m Atila
          </h2>
          <ul class="flex flex-col gap-2">
            <li>
              <img
                class="inline-block mr-2 relative -top-1"
                src="/solidjs.svg"
              />
              SolidJS DX team
            </li>
            <li>
              <img class="inline-block mr-2 relative -top-1" src="/tauri.svg" />
              Tauri Working-Group
            </li>
            <li>
              <img class="inline-block mr-2 relative -top-1" src="/gde.svg" />
              GDE: Web Tech
            </li>
            <li>
              <Laptop class="inline-block w-4 mr-2 relative -top-px text-cyan-300" />
              Compulsive Side-Project Creator
            </li>
            <li>
              <Trophy class="inline-block w-4 mr-2 relative -top-px text-amber-300" />
              Unawarded Developer of the Month
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
