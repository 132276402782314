export function Listup() {
  return (
    <svg
      width="300"
      height="300"
      viewBox="0 0 300 300"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1_41)">
        <path
          d="M274.694 186.286H99.917C91.7143 186.286 85.0446 179.809 85.0446 171.854V65.4316C85.0446 57.4774 91.7143 51 99.917 51H274.694C282.897 51 289.566 57.4774 289.566 65.4316V171.854C289.566 179.809 282.897 186.286 274.694 186.286ZM99.917 60.0217C96.843 60.0217 94.3388 62.4457 94.3388 65.4316V171.854C94.3388 174.84 96.843 177.264 99.917 177.264H274.694C277.768 177.264 280.272 174.84 280.272 171.854V65.4316C280.272 62.4457 277.768 60.0217 274.694 60.0217H99.917Z"
          fill="white"
        />
        <path
          d="M187.305 141.193C186.238 141.193 185.162 140.832 184.296 140.118L91.329 63.4571C89.3706 61.8437 89.1379 58.9944 90.7993 57.1001C92.4607 55.2059 95.3983 54.9731 97.3486 56.5864L187.305 130.767L277.254 56.5864C279.213 54.9731 282.142 55.2059 283.804 57.1001C285.465 58.9944 285.232 61.8437 283.274 63.4571L190.307 140.118C189.44 140.832 188.365 141.193 187.297 141.193H187.305Z"
          fill="white"
        />
        <path
          d="M94.3308 181.775C93.1991 181.775 92.0674 181.374 91.1685 180.571C89.2904 178.878 89.178 176.02 90.9277 174.198L151.356 111.062C153.106 109.24 156.044 109.128 157.922 110.829C159.8 112.523 159.912 115.38 158.163 117.202L97.7339 180.338C96.8189 181.294 95.5748 181.783 94.3228 181.783L94.3308 181.775Z"
          fill="white"
        />
        <path
          d="M280.272 181.775C279.028 181.775 277.784 181.294 276.861 180.33L216.432 117.194C214.683 115.372 214.795 112.515 216.673 110.821C218.551 109.128 221.497 109.232 223.238 111.054L283.667 174.19C285.417 176.012 285.304 178.87 283.426 180.563C282.527 181.366 281.396 181.767 280.264 181.767L280.272 181.775Z"
          fill="white"
        />
        <path
          d="M99.3391 60.0217C96.249 60.0217 93.7288 62.4457 93.7288 65.4316V171.854C93.7288 174.84 96.241 177.264 99.3391 177.264H275.103C278.193 177.264 280.713 174.84 280.713 171.854V65.4316C280.713 62.4457 278.201 60.0217 275.103 60.0217H99.3391Z"
          fill="#102431"
        />
        <path
          d="M235.093 218.143H60.3161C52.1134 218.143 45.4437 211.666 45.4437 203.711V97.2886C45.4437 89.3344 52.1134 82.857 60.3161 82.857H235.093C243.296 82.857 249.965 89.3344 249.965 97.2886V203.711C249.965 211.666 243.296 218.143 235.093 218.143ZM60.3161 91.8788C57.2421 91.8788 54.7379 94.3028 54.7379 97.2886V203.711C54.7379 206.697 57.2421 209.121 60.3161 209.121H235.093C238.167 209.121 240.671 206.697 240.671 203.711V97.2886C240.671 94.3028 238.167 91.8788 235.093 91.8788H60.3161Z"
          fill="white"
        />
        <path
          d="M147.705 173.05C146.637 173.05 145.562 172.689 144.695 171.975L51.7281 95.3141C49.7698 93.7008 49.537 90.8514 51.1984 88.9571C52.8598 87.0629 55.7974 86.8301 57.7477 88.4434L147.705 162.624L237.661 88.4434C239.62 86.8301 242.549 87.0629 244.211 88.9571C245.872 90.8514 245.639 93.7008 243.681 95.3141L150.714 171.975C149.848 172.689 148.772 173.05 147.705 173.05Z"
          fill="white"
        />
        <path
          d="M54.7379 213.632C53.6062 213.632 52.4745 213.231 51.5756 212.428C49.6975 210.735 49.5851 207.877 51.3348 206.055L111.764 142.919C113.513 141.097 116.451 140.985 118.329 142.686C120.207 144.38 120.319 147.237 118.57 149.059L58.141 212.195C57.226 213.151 55.9819 213.64 54.7299 213.64L54.7379 213.632Z"
          fill="white"
        />
        <path
          d="M240.671 213.632C239.427 213.632 238.183 213.151 237.26 212.187L176.831 149.051C175.082 147.229 175.194 144.372 177.072 142.678C178.95 140.985 181.896 141.089 183.637 142.911L244.066 206.047C245.816 207.869 245.704 210.727 243.825 212.42C242.926 213.223 241.795 213.624 240.663 213.624L240.671 213.632Z"
          fill="white"
        />
        <path
          d="M59.8827 91.8788C56.8006 91.8788 54.2885 94.3027 54.2885 97.2886V203.711C54.2885 206.697 56.7926 209.121 59.8827 209.121H235.077C238.159 209.121 240.671 206.697 240.671 203.711V97.2886C240.671 94.3027 238.167 91.8788 235.077 91.8788H59.8827Z"
          fill="#102431"
        />
        <path
          d="M19.8724 123.736C16.7984 123.736 14.2943 126.16 14.2943 129.146V235.568C14.2943 238.554 16.7984 240.978 19.8724 240.978H194.649C197.723 240.978 200.227 238.554 200.227 235.568V129.146C200.227 126.16 197.723 123.736 194.649 123.736H19.8724Z"
          fill="#102431"
        />
        <path
          d="M194.649 250H19.8724C11.6697 250 5 243.523 5 235.568V129.146C5 121.191 11.6697 114.714 19.8724 114.714H194.649C202.852 114.714 209.522 121.191 209.522 129.146V235.568C209.522 243.523 202.852 250 194.649 250ZM19.8724 123.736C16.7984 123.736 14.2943 126.16 14.2943 129.146V235.568C14.2943 238.554 16.7984 240.978 19.8724 240.978H194.649C197.723 240.978 200.228 238.554 200.228 235.568V129.146C200.228 126.16 197.723 123.736 194.649 123.736H19.8724Z"
          fill="white"
        />
        <path
          d="M14.2943 245.489C13.1626 245.489 12.0309 245.088 11.132 244.285C9.25388 242.592 9.14151 239.734 10.8912 237.912L71.3199 174.776C73.0696 172.954 76.0072 172.842 77.8853 174.543C79.7634 176.237 79.8758 179.094 78.1261 180.916L17.7054 244.052C16.7904 245.008 15.5464 245.497 14.2943 245.497V245.489Z"
          fill="white"
        />
        <path
          d="M200.227 245.489C198.983 245.489 197.739 245.008 196.816 244.044L136.388 180.908C134.638 179.086 134.75 176.229 136.628 174.535C138.507 172.842 141.452 172.946 143.194 174.768L203.623 237.904C205.372 239.726 205.26 242.584 203.382 244.277C202.483 245.08 201.351 245.481 200.219 245.481L200.227 245.489Z"
          fill="white"
        />
        <path
          d="M105.912 183.437C108.802 185.435 112.646 185.371 115.472 183.292L280.12 61.9079C282.078 60.2946 285.007 60.5274 286.669 62.4216C288.33 64.3159 288.098 67.1653 286.139 68.7786L114.308 208.535C113.441 209.25 112.365 209.611 111.298 209.611C110.231 209.611 109.155 209.25 108.288 208.535L15.3216 131.875C13.3632 130.261 13.1304 127.412 14.7919 125.518C16.4533 123.623 19.3908 123.391 21.3412 125.004L105.912 183.437Z"
          fill="#FCD34D"
          stroke="#FCD34D"
          stroke-width="18"
          stroke-miterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_41">
          <rect
            width="290"
            height="199"
            fill="white"
            transform="translate(5 51)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
