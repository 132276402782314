export function Footer() {
  return (
    <footer class="pt-12 pb-6 mx-auto max-w-2xl">
      <div class="flex justify-between items-center">
        <p class="text-center text-gray-400 text-md">
          by{" "}
          <a href="https://atila.io" rel="noopener">
            Atila.io
          </a>
        </p>
        <ul class="flex flex-row gap-5">
          <li>ytube</li>
          <li>
            <img src="/bluesky.svg" />
          </li>
          <li>
            <img src="/xicon.svg" />
          </li>
          <li>
            <img src="/linkedin.svg" />
          </li>
        </ul>
      </div>
    </footer>
  );
}
